









































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import router from "@/router";
import TournamentModel from '@/models/TournamentModel';

@Component({
  components: {},
})
export default class Data extends Vue {
  private database = firebase.database();

  public list = Array<TournamentModel>();

  public LoadData(): void {
    const userId = firebase.auth().currentUser?.uid;

    if(userId != undefined) {
      const ref = this.database.ref();
      
      ref.child('tournaments')
        .child(userId)
        .once('value')
        .then((snapshot) => {
          this.list = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  public View(tournamentId: string, tournamentType: number): void {
    if(tournamentType === 1) {
      router.push({ name: 'View_V1', params: { tournamentId: tournamentId } });
    } else if(tournamentType === 2) {
      router.push({ name: 'View_V2', params: { tournamentId: tournamentId } });
    }
  }

  public Remove(tournamentId: string): void {
    const userId = firebase.auth().currentUser?.uid;

    this.database.ref()
    .child(`tournaments/${userId}/${tournamentId}`)
    .remove()
    .then(() => {
      this.LoadData();
    })
    .catch((error) => {
      console.error(error);
    });
  }
}
