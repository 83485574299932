



































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TournamentModel from '@/models/TournamentModel';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

@Component({
  components: {
  },
})
export default class View_V1 extends Vue {
  @Prop() private tournamentId!: string;
  
  private tournamentModel = new TournamentModel();

  private result = ['勝ち', '負け', 'CO'];

  private database = firebase.database();

  @Watch('tournamentId')
  OnChangeTournamentId(): void {
    this.LoadData();  
  }

  mounted(): void {
    this.LoadData();
  }

  private LoadData() {
    const userId = firebase.auth().currentUser?.uid;
    
    if(userId != undefined) {
      const ref = this.database.ref();
      const data = ref.child(`tournaments/${userId}/${this.tournamentId}`);
      data.once('value').then((snapshot) => {
        this.tournamentModel = snapshot.val();
      })
      .catch(error => {
        console.error(error);
      });
    }
  }
}
